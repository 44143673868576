import cx from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import { sha256 } from 'js-sha256'
import {
  type BaseSyntheticEvent,
  type FormEvent,
  useCallback,
  useContext,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import { type SanityDemoFormBlock } from '@data/sanity/queries/types/blocks'
import { AnalyticsEventName } from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { fadeAnimation } from '@lib/animate'
import { type DemoFormValues } from '@lib/form'
import { FormValueProvider } from '@lib/form-value-context'
import { LanguageContext } from '@lib/language-context'
import { addEmailToNewsletterList, bookADemo } from '@lib/services'
import { StringsContext } from '@lib/strings-context'

import Alert from '@components/alert'
import Button, {
  getButtonColor,
  getButtonIconAlignment,
  getButtonSize,
  getButtonVariant,
} from '@components/buttons/button'
import Checkbox from '@components/checkbox'
import InputField from '@components/input-field/with-form-register'
import TelInputField from '@components/tel-input-field'
import SimplePortableText from '@components/simple-portable-text'
import ComplexPortableText from '@components/complex-portable-text'

type DemoFormProps = Omit<SanityDemoFormBlock, '_key' | '_type'> & {
  id: string
  className?: string
}

const DemoForm = ({
  service,
  klaviyoListID,
  klaviyoNewsletterListID,
  newsletterStatement,
  terms,
  submit,
  successMsg,
  errorMsg,
  className,
  buttonStyle,
  analytics,
  strings: formStrings,
}: DemoFormProps) => {
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [formValues, setFormValues] = useState<DemoFormValues>()

  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
    setValue,
    trigger,
  } = useForm<DemoFormValues>()
  const hasAgreed = watch('acceptTerms')
  const subscribeToNewsletter = watch('subscribeToNewsletter')
  register('phoneNumber', {
    required: formStrings?.phoneNumberMissing ?? strings.phoneNumberMissing,
  })
  const firstNameRegister = register('firstName', {
    required: formStrings?.firstNameMissing ?? strings.firstNameMissing,
  })
  const lastNameRegister = register('lastName', {
    required: formStrings?.lastNameMissing ?? strings.lastNameMissing,
  })
  const emailRegister = register('email', {
    required: formStrings?.emailMissing ?? strings.emailMissing,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: formStrings?.emailInvalid ?? strings.emailInvalid,
    },
  })
  const companyRegister = register('company')
  const acceptTermsRegister = register('acceptTerms')
  const subscribeToNewsletterRegister = register('subscribeToNewsletter')

  const resetForm = (event: FormEvent) => {
    event.preventDefault()

    reset()
    setIsError(false)
    setIsSuccess(false)
    setIsSubmitting(false)
  }

  const handlePhoneChange = useCallback(
    (value: string) => {
      setValue('phoneNumber', value)
      trigger('phoneNumber')
    },
    [setValue, trigger]
  )

  const onSubmit = async (
    demoFormValues: DemoFormValues,
    event?: BaseSyntheticEvent
  ) => {
    event?.preventDefault()

    setIsError(false)
    setIsSuccess(false)
    setFormValues(demoFormValues)

    const demoListId = service === 'klaviyo' ? klaviyoListID : null
    const newsletterListId =
      service === 'klaviyo' ? klaviyoNewsletterListID : null

    // Set an error if there is no list supplied
    if (!demoListId) {
      setIsError(true)
      return
    }

    // Stop if accepting of terms is required
    if (!hasAgreed && terms) {
      return
    }

    setIsSubmitting(true)

    try {
      if (subscribeToNewsletter && !!newsletterListId) {
        await addEmailToNewsletterList(
          locale,
          service,
          newsletterListId,
          demoFormValues.email,
          `${demoFormValues.firstName} ${demoFormValues.lastName}`
        )
        triggerAnalyticsEvent(AnalyticsEventName.NewsletterSignUp)
      }

      await bookADemo(
        locale,
        service,
        demoListId,
        demoFormValues.email,
        demoFormValues.phoneNumber,
        demoFormValues.firstName,
        demoFormValues.lastName,
        demoFormValues.company
      )
      triggerAnalyticsEvent(
        analytics?.submitEventName ?? AnalyticsEventName.BookADemo,
        {
          user_data: {
            sha256_email_address: sha256(demoFormValues.email),
            phone_number: demoFormValues.phoneNumber,
            address: {
              first_name: demoFormValues.firstName,
              last_name: demoFormValues.lastName,
            },
            companyName: demoFormValues.company,
          },
        }
      )
      setIsSuccess(true)
    } catch (error) {
      console.log(error)
      setIsError(true)
    }

    setIsSubmitting(false)
  }

  if (!service) {
    return null
  }

  const isDisabled = isSubmitting || (terms && !hasAgreed)

  return (
    <FormValueProvider
      formValues={{
        'book-a-demo-email': formValues?.email,
        'book-a-demo-firstName': formValues?.firstName,
        'book-a-demo-lastName': formValues?.lastName,
        'book-a-demo-phoneNumber': formValues?.phoneNumber?.replace(/ /g, ''),
        'book-a-demo-company': formValues?.company,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={cx(className)}>
        <AnimatePresence mode="wait">
          {!isError && !isSuccess && (
            <motion.div
              initial="hide"
              animate="show"
              exit="hide"
              variants={fadeAnimation}
            >
              <div className="flex flex-col sm:flex-row sm:gap-4">
                <InputField
                  type="text"
                  formRegister={firstNameRegister}
                  placeholder={
                    formStrings?.firstNamePlaceholder ??
                    strings.firstNamePlaceholder
                  }
                  label={formStrings?.firstName ?? strings.firstName}
                  errorMessage={errors.firstName?.message}
                  className="sm:w-1/2 mb-4"
                />

                <InputField
                  type="text"
                  formRegister={lastNameRegister}
                  placeholder={
                    formStrings?.lastNamePlaceholder ??
                    strings.lastNamePlaceholder
                  }
                  label={formStrings?.lastName ?? strings.lastName}
                  errorMessage={errors.lastName?.message}
                  className="sm:w-1/2 mb-4"
                />
              </div>

              <InputField
                type="email"
                formRegister={emailRegister}
                placeholder={
                  formStrings?.emailAddressPlaceholder ??
                  strings.emailAddressPlaceholder
                }
                label={formStrings?.emailAddress ?? strings.emailAddress}
                errorMessage={errors.email?.message}
                className="mb-4"
              />

              <TelInputField
                name="phone"
                placeholder={
                  formStrings?.phoneNumberPlaceholder ??
                  strings.phoneNumberPlaceholder
                }
                label={formStrings?.phoneNumber ?? strings.phoneNumber}
                errorMessage={errors.phoneNumber?.message}
                onChange={handlePhoneChange}
                className="mb-4"
              />

              <InputField
                type="text"
                formRegister={companyRegister}
                placeholder={
                  formStrings?.companyPlaceholder ?? strings.companyPlaceholder
                }
                label={formStrings?.company ?? strings.company}
                errorMessage={errors.company?.message}
                className="mb-4"
              />

              {!!newsletterStatement && !!klaviyoNewsletterListID && (
                <Checkbox
                  formRegister={subscribeToNewsletterRegister}
                  className="mb-3"
                >
                  <SimplePortableText
                    className="rc rc-checkbox"
                    content={newsletterStatement}
                  />
                </Checkbox>
              )}

              {!!terms && (
                <Checkbox formRegister={acceptTermsRegister} className="mb-3">
                  <SimplePortableText
                    className="rc rc-checkbox"
                    content={terms}
                  />
                </Checkbox>
              )}

              <div className="flex justify-center">
                <Button
                  type="submit"
                  variant={
                    buttonStyle?.variant
                      ? getButtonVariant(buttonStyle.variant)
                      : 'filled'
                  }
                  size={
                    buttonStyle?.size
                      ? getButtonSize(buttonStyle.size)
                      : 'normal'
                  }
                  color={
                    buttonStyle?.color
                      ? getButtonColor(buttonStyle.color)
                      : 'default'
                  }
                  icon={buttonStyle?.icon}
                  iconAlignment={getButtonIconAlignment(
                    buttonStyle?.iconAlignment
                  )}
                  disabled={isDisabled}
                  className={cx('mt-3', {
                    'w-full': buttonStyle?.isFullWidth,
                  })}
                >
                  {submit}
                </Button>
              </div>
            </motion.div>
          )}

          {isSuccess && (
            <motion.div
              key="success"
              initial="hide"
              animate="show"
              exit="hide"
              variants={fadeAnimation}
            >
              <Alert>
                <ComplexPortableText
                  content={successMsg}
                  className="rc rc-alert"
                />
              </Alert>
            </motion.div>
          )}

          {isError && (
            <motion.div
              key="error"
              initial="hide"
              animate="show"
              exit="hide"
              variants={fadeAnimation}
            >
              <Alert
                buttonText={
                  formStrings?.buttonTryAgain ?? strings.buttonTryAgain
                }
                onClick={resetForm}
              >
                <ComplexPortableText
                  content={errorMsg}
                  className="rc rc-alert"
                />
              </Alert>
            </motion.div>
          )}
        </AnimatePresence>
      </form>
    </FormValueProvider>
  )
}

export default DemoForm
