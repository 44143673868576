import { useContext } from 'react'

import {
  OrderFinancialStatus,
  OrderFulfillmentStatus,
} from '@data/shopify/storefront/types'
import { type SanityAccountOrderListStrings } from '@data/sanity/queries/types/blocks'
import { getPriceWithCurrency } from '@lib/format'
import { LanguageContext } from '@lib/language-context'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'
import { useUserOrderList } from '@lib/user/hooks'

import Button from '@components/buttons/button'
import ComplexPortableText from '@components/complex-portable-text'

interface AccountOrderListProps {
  className?: string
  accountOrderListStrings: SanityAccountOrderListStrings
}

type PaymentStatusValues<T> = {
  [P in OrderFinancialStatus]: T
}

type FulfillmentStatusValues<T> = {
  [P in OrderFulfillmentStatus]: T
}

/**
 * Gets order payment status texts.
 */
const getPaymentStatuses = (
  accountOrderListStrings: SanityAccountOrderListStrings
): PaymentStatusValues<string> => ({
  [OrderFinancialStatus.Authorized]:
    accountOrderListStrings.accountOrderListPaymentStatusAuthorized,
  [OrderFinancialStatus.Paid]:
    accountOrderListStrings.accountOrderListPaymentStatusPaid,
  [OrderFinancialStatus.PartiallyPaid]:
    accountOrderListStrings.accountOrderListPaymentStatusPartiallyPaid,
  [OrderFinancialStatus.PartiallyRefunded]:
    accountOrderListStrings.accountOrderListPaymentStatusPartiallyRefunded,
  [OrderFinancialStatus.Pending]:
    accountOrderListStrings.accountOrderListPaymentStatusPending,
  [OrderFinancialStatus.Refunded]:
    accountOrderListStrings.accountOrderListPaymentStatusRefunded,
  [OrderFinancialStatus.Voided]:
    accountOrderListStrings.accountOrderListPaymentStatusVoided,
})

/**
 * Gets order fulfillment status texts.
 */
const getFulfillmentStatuses = (
  accountOrderListStrings: SanityAccountOrderListStrings
): FulfillmentStatusValues<string> => ({
  [OrderFulfillmentStatus.Fulfilled]:
    accountOrderListStrings.accountOrderListFulfillmentStatusFulfilled,
  [OrderFulfillmentStatus.InProgress]:
    accountOrderListStrings.accountOrderListFulfillmentStatusInProgress,
  [OrderFulfillmentStatus.OnHold]:
    accountOrderListStrings.accountOrderListFulfillmentStatusOnHold,
  [OrderFulfillmentStatus.Open]:
    accountOrderListStrings.accountOrderListFulfillmentStatusOpen,
  [OrderFulfillmentStatus.PartiallyFulfilled]:
    accountOrderListStrings.accountOrderListFulfillmentStatusPartiallyFulfilled,
  [OrderFulfillmentStatus.PendingFulfillment]:
    accountOrderListStrings.accountOrderListFulfillmentStatusPendingFulfillment,
  [OrderFulfillmentStatus.Restocked]:
    accountOrderListStrings.accountOrderListFulfillmentStatusRestocked,
  [OrderFulfillmentStatus.Scheduled]:
    accountOrderListStrings.accountOrderListFulfillmentStatusScheduled,
  [OrderFulfillmentStatus.Unfulfilled]:
    accountOrderListStrings.accountOrderListFulfillmentStatusUnfulfilled,
})

const AccountOrderList = ({
  accountOrderListStrings,
  className,
}: AccountOrderListProps) => {
  const { currency, taxRate } = useContext(ShopContext)
  const { locale } = useContext(LanguageContext)
  const strings = useContext(StringsContext)
  const { orders, hasMoreOrders, loadOrders, isLoadingOrders } =
    useUserOrderList()

  const paymentStatuses = getPaymentStatuses(accountOrderListStrings)
  const fulfillmentStatuses = getFulfillmentStatuses(accountOrderListStrings)

  const dateFormat = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  return (
    <div className={className}>
      <h4>{accountOrderListStrings.accountOrderListHeading}</h4>

      {orders.length === 0 && !isLoadingOrders && (
        <ComplexPortableText
          className="rc mt-3"
          content={accountOrderListStrings.accountOrderListEmpty}
        />
      )}

      {orders.length > 0 && (
        <div className="mt-5">
          <div className="justify-evenly py-4 px-5 bg-gray-50 text-xs uppercase font-medium hidden sm:flex border-t">
            <div className="w-[20%] pr-3">
              {accountOrderListStrings.accountOrderListOrder}
            </div>
            <div className="w-[20%] pr-3">
              {accountOrderListStrings.accountOrderListDate}
            </div>
            <div className="w-[20%] pr-3">
              {accountOrderListStrings.accountOrderListPaymentStatus}
            </div>
            <div className="w-[20%] pr-3">
              {accountOrderListStrings.accountOrderListFulfillmentStatus}
            </div>
            <div className="w-[20%] text-right">
              {accountOrderListStrings.accountOrderListTotal}
            </div>
          </div>

          {orders.map((order) => (
            <a
              key={order.id}
              className="flex flex-col sm:flex-row cursor-pointer py-5 sm:px-5 border-t text-sm"
              href={order.url}
              target="_blank"
              rel="noreferrer"
            >
              <div className="sm:w-[20%] flex justify-between sm:block mb-3 sm:mb-0">
                <div className="sm:hidden uppercase font-medium text-xs">
                  {accountOrderListStrings.accountOrderListOrder}
                </div>

                <div className="text-right sm:text-left sm:pr-3">
                  {order.id}
                </div>
              </div>

              <div className="sm:w-[20%] flex justify-between sm:block mb-3 sm:mb-0">
                <div className="sm:hidden uppercase font-medium text-xs">
                  {accountOrderListStrings.accountOrderListDate}
                </div>

                <div className="text-right sm:text-left sm:pr-3">
                  {dateFormat.format(new Date(order.date))}
                </div>
              </div>

              <div className="sm:w-[20%] flex justify-between sm:block mb-3 sm:mb-0">
                <div className="sm:hidden uppercase font-medium text-xs">
                  {accountOrderListStrings.accountOrderListPaymentStatus}
                </div>

                <div className="text-right sm:text-left sm:pr-3">
                  {order.paymentStatus
                    ? paymentStatuses[order.paymentStatus]
                    : '-'}
                </div>
              </div>

              <div className="sm:w-[20%] flex justify-between sm:block mb-3 sm:mb-0">
                <div className="sm:hidden uppercase font-medium text-xs">
                  {accountOrderListStrings.accountOrderListFulfillmentStatus}
                </div>

                <div className="text-right sm:text-left sm:pr-3">
                  {fulfillmentStatuses[order.fulfillmentStatus]}
                </div>
              </div>

              <div className="sm:w-[20%] flex justify-between sm:block">
                <div className="sm:hidden uppercase font-medium text-xs">
                  {accountOrderListStrings.accountOrderListTotal}
                </div>

                <div className="text-right">
                  {getPriceWithCurrency(order.total * 100, currency, {
                    taxRate,
                    thousandSeparator: ',',
                  })}
                </div>
              </div>
            </a>
          ))}

          {hasMoreOrders && (
            <div className="mt-4 text-center">
              <Button
                variant="filled"
                size="small"
                disabled={isLoadingOrders}
                className="min-w-[136px]"
                onClick={loadOrders}
              >
                {isLoadingOrders
                  ? strings.buttonLoading
                  : strings.buttonLoadMore}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default AccountOrderList
